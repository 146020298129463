import axios from "axios";
import getEnv from "./env";
import { isBrowser } from "./isBrowser";

const getAxiosConfig = () => {
  const commonHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const headers = isBrowser()
    ? commonHeaders
    : {
        ...commonHeaders,
        "User-Agent": "VendorPortalFE",
        "x-capstg-key": getEnv().REACT_APP_VP_API_KEY,
      };

  return {
    ...(!isBrowser() && {
      baseURL: getEnv().REACT_APP_VP_BASE_URL,
      withCredentials: getEnv().NODE_ENV === "production",
    }),
    headers,
  };
};

const authAxios = axios.create(getAxiosConfig());

export const setAxiosFEConfig = () => {
  authAxios.defaults.baseURL = getEnv().REACT_APP_VP_BASE_URL;
  authAxios.defaults.withCredentials = getEnv().NODE_ENV === "production";
  authAxios.defaults.headers["x-capstg-key"] = getEnv().REACT_APP_VP_API_KEY;
};

export default authAxios;
